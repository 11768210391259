import { parseHTTPError } from '@/helpers/errors'

const withAsyncAction = function ({
  key,
  initialState = null,
  fetcher: {
    methodName,
    handler,
    stateUpdater = ({ currentState }) => currentState
  }
}) {
  return {
    data: () => ({
      [key]: {
        error: null,
        loading: false,
        data: initialState
      }
    }),
    methods: {
      async [methodName](props) {
        this[key].loading = true

        try {
          const data = await handler(props)

          const updatedData = stateUpdater({
            prevState: this[key].data,
            currentState: data
          })

          this[key].data = updatedData
          this[key].error = null
          return updatedData
        } catch (error) {
          this[key].error = parseHTTPError(error)
          throw error
        } finally {
          this[key].loading = false
        }
      }
    }
  }
}

export default withAsyncAction
